import React from 'react';
import './page1.css';


class Page1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [{
                title: '上海微信公众号',
                desc: '上海权威新房&二手房咨询平台',
                desc2: '提供前滩，内环，中环等热门板块新房快捷信息',
                type: 'qrcodeWXSH',
                image: 'https://sp.ipangsell.com/qrcode/xp-mp.jpg'
            },{
                title: '杭州微信公众号',
                desc: '杭州权威新房&二手房咨询平台',
                desc2: '关注即刻获得最全楼盘信息&最新政策解读',
                type: 'qrcodeWXHZ',
                image: 'https://sp.ipangsell.com/qrcode/hz_wx.jpg'
            },{
                title: '微信小程序',
                desc: '全面购房工具，买房一键查询',
                desc2: '了解购房资格、购房积分、二套房、公积金等购房知识',
                type: 'qrcodeXCX',
                image: 'https://sp.ipangsell.com/xcx/mortgage.jpg'
            }]
        }
    }
    render() {
        return (
            <div className="qrcodeBody">
                {
                    this.state.list.map((item,index) => (
                        <div className="box2" key={item.type}>
                            <p className="title">{item.title}</p>
                            <div className="circleSty">
                                <img src={item.image} className="imgSty" alt="qrcodeWX" />
                            </div>
                            <p className="desc">{item.desc}</p>
                            <p className="desc2">{item.desc2}</p>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default Page1