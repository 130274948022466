
import './bottom.css';

//点击备案号链接跳转
function chandleLink() {
    const wind = window.open('about:blank');
    wind.location.href = 'https://beian.miit.gov.cn'
}

function Bottom(props){
    return (
        <div className="bottom">
            <div className="bottomBox">
                <div>上海侃房网络科技有限公司<span className="left-5">上海普陀区金沙江路1006号华师大科创园11楼</span></div>
                <div className="copyRight">Copyright @copyright 2014-2022, All Rights Reserved. <span className='linkText' onClick={ chandleLink }>沪ICP备18001631号-2</span></div>
            </div>
        </div>
    )
}

export default Bottom