
import './nav.css';
function Navs(props){
    return (
        <div className="topBox">
            <div className="navBox">
                <img className="logo" src="https://sp.ipangsell.com/xcx/icon/icon-logo.png" alt="logo" />
            </div>
        </div>
    )
}

export default Navs